<template>
  <span v-html="userServiceLabel" />
</template>

<script>
import { typeServiceCode } from "../../helpers/constants";

export default {
  name: "UserServiceSelect",
  props: {
    reference: {
      type: String,
      required: true,
    },
    serviceIndex: {
      type: String,
      required: true,
    },
    typeServiceCode: {
      type: String,
      required: true,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    userServiceLabel() {
      try {
        if (this.typeServiceCode === typeServiceCode.charge_card) {
          const visa = JSON.parse(this.reference);
          return `${visa.bank} ${this.isConfirm ? "<br/> ID : " : " - "}${
            visa.ref
          }`;
        } else return this.reference;
      } catch (e) {
        return this.reference;
      }
    },
  },
};
</script>

<style scoped></style>
