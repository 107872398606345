import { render, staticRenderFns } from "./UserServiceForm.vue?vue&type=template&id=349891af&scoped=true&"
import script from "./UserServiceForm.vue?vue&type=script&lang=js&"
export * from "./UserServiceForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349891af",
  null
  
)

export default component.exports