var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payer-un-service be-row justify-content-center"},[_c('div',{staticClass:"left-side d-none d-xxl-flex flex-column"},[_c('div',{staticClass:"left-icon-container"},[_vm._m(0),_c('div',{staticClass:"left-icon-text"},[_vm._v(_vm._s(_vm.title.label))])])]),_c('div',{staticClass:"right-side  px-0 h-100 "},[_c('div',{staticClass:"be-card"},[_c('header-with-divider',{attrs:{"text":_vm.title.label,"color":"blue"}}),(_vm.isShoppingAvailable)?_c('div',{staticClass:"be-card-body h-100"},[(_vm.beFormErrorMessage)?_c('be-form-error',{attrs:{"message":_vm.beFormErrorMessage}}):_vm._e(),(_vm.isLohceShopping)?_c('ShoppingFormLohce',{attrs:{"service":_vm.service}}):_vm._e(),(!_vm.hideServiceSelect)?_c('div',{staticClass:"be-row position-relative"},[_c('div',{staticClass:"col-lg-6 px-0 pr-lg-4"},[_c('be-multiselect',{attrs:{"options":_vm.services,"disabled":_vm.services.length === 1,"track-by":"id","label":"label","inputLabel":_vm.$t('base.choose_service'),"placeholder":_vm.$t('common.choose'),"close-on-select":true,"initialValue":_vm.service},on:{"input":_vm.onChooseService},model:{value:(_vm.ddd),callback:function ($$v) {_vm.ddd=$$v},expression:"ddd"}})],1),(!_vm.isLohceShopping)?_c('div',{staticClass:"col-lg-6 px-0 position-unset"},[(!_vm.isConfirm)?[(_vm.isUserServiceShop)?_c('UserServiceSelector',{attrs:{"onToggleEdit":_vm.onToggleEdit,"service":_vm.service,"loading":_vm.loading},model:{value:(_vm.userService),callback:function ($$v) {_vm.userService=$$v},expression:"userService"}}):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.showEditPlaceholder)?_c('div',{staticClass:"divider my-30"}):_vm._e(),(_vm.showEditPlaceholder)?_c('div',{class:{
              'edit-placeholder': true,
              'edit-placeholder-with-switch': _vm.isRememberUserService,
              'edit-placeholder-with-card-inputs': _vm.isVisaUserServiceForm,
            }}):_vm._e()]):_vm._e(),(!_vm.isLohceShopping)?_c('div',{staticClass:"card-body p-0"},[(!_vm.isConfirm)?[_c('div',{},[(_vm.showEditPlaceholder && _vm.isCanalPlusShopping)?_c('div',{staticClass:"divider mt-30"}):_vm._e(),(_vm.isCanalPlusShopping)?_c('CanalPlusProductList',{attrs:{"isInvalid":_vm.$v.quoteInput.amount.$error,"errorMessage":_vm.canalErrorMessage},on:{"input":function($event){return _vm.$v.quoteInput.amount.$touch()}},model:{value:(_vm.quoteInput),callback:function ($$v) {_vm.quoteInput=$$v},expression:"quoteInput"}}):_vm._e()],1),(_vm.isBuyingCardShopping)?_c('BuyCardFormInput',{on:{"process":_vm.handleVisaCardProcess}}):_vm._e(),(_vm.isManuelReferenceBill)?_c('div',{staticClass:"be-row col-12 col-lg-6 px-0 mt-4 pr-lg-4"},[_c('BaseInput',{attrs:{"label":"Numéro de facture","type":"text","isInvalid":_vm.$v.userService.reference.$error,"placeholder":"Entrez le numéro de facture","description":_vm.isSodeciBillRef &&
                    'Entrez les 09 premiers chiffres représentant la référence de votre contrat',"errorMessage":_vm.$v.userService.reference.$error &&
                    ((!_vm.$v.userService.reference.required &&
                      'Veuillez saisir le numéro de votre facture') ||
                      ((!_vm.$v.userService.reference.minLength ||
                        !_vm.$v.userService.reference.maxLength) &&
                        ("La valeur du numéro de facture doit être " + _vm.numberBillLength + " caractères")))},on:{"input":function($event){return _vm.$v.userService.reference.$touch()}},model:{value:(_vm.userService.reference),callback:function ($$v) {_vm.$set(_vm.userService, "reference", $$v)},expression:"userService.reference"}})],1):_vm._e(),(
                _vm.service !== null &&
                  (!_vm.isBuyingCardShopping || this.visaCard !== null)
              )?_c('div',{staticClass:"divider my-30"}):_vm._e(),(_vm.canShowAmountField)?_c('div',{staticClass:"be-row"},[_c('div',{staticClass:"col-lg-6 px-0 pr-lg-4"},[_c('BaseInput',{attrs:{"label":_vm.$t('common.amount'),"type":"number","isInvalid":_vm.$v.quoteInput.amount.$error,"placeholder":_vm.$t('common.insert_amount'),"errorMessage":_vm.amountErrorMessage},on:{"input":function($event){return _vm.$v.quoteInput.amount.$touch()}},model:{value:(_vm.quoteInput.amount),callback:function ($$v) {_vm.$set(_vm.quoteInput, "amount", $$v)},expression:"quoteInput.amount"}})],1)]):_vm._e()]:_vm._e(),(_vm.isConfirm)?_c('ShoppingConfirm',{attrs:{"quote":_vm.quote,"quoteInput":_vm.quoteInput,"service":_vm.service,"userService":_vm.userService,"feeAmount":_vm.feeAmount,"totalAmount":_vm.totalAmount,"bill_number":_vm.bill_number,"due_date":_vm.due_date,"visaCard":_vm.visaCard}}):_vm._e()],2):_vm._e(),(
            _vm.service !== null &&
              (!_vm.isBuyingCardShopping || this.visaCard !== null) &&
              _vm.service.api !== _vm.serverApiCode.lohce
          )?[_c('div',{staticClass:"be-row justify-content-end"},[(_vm.isConfirm)?_c('div',{staticClass:"col be-row justify-content-center col-sm-auto px-0 mt-4 mt-lg-0 align-items-center"},[_c('span',{staticClass:"f-w-600 fermer pointer mr-4",attrs:{"role":"button","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.cancelForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")])]):_vm._e(),_c('div',{staticClass:"col be-row justify-content-center col-sm-auto px-0 mt-4 mt-lg-0"},[_c('BaseButton',{attrs:{"buttonClass":"be-btn primary blue lg mb-0","disabled":_vm.$v.$invalid || _vm.loading,"loading":_vm.loading,"type":"button","showLoading":true},on:{"click":function($event){$event.preventDefault();return _vm.handleShoppingBtn.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.btnTextAndIcon.text)+" ")])],1)])]:_vm._e()],2):_c('div',{staticClass:"service-not-available"},[_c('i',{staticClass:"not-available-card icon-card"}),_c('span',{staticClass:"not-available-text"},[_vm._v(_vm._s(_vm.$t("shopping.service_not_available")))])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-div-payer"},[_c('i',{staticClass:"icon-card-fill"})])}]

export { render, staticRenderFns }