<template>
  <div class="payer-un-service be-row justify-content-center">
    <div class="left-side d-none d-xxl-flex flex-column">
      <div class="left-icon-container">
        <div class="icon-div-payer">
          <i class="icon-card-fill"></i>
        </div>
        <div class="left-icon-text">{{ title.label }}</div>
      </div>
    </div>
    <div class="right-side  px-0 h-100 ">
      <div class="be-card">
        <header-with-divider :text="title.label" color="blue" />
        <div class="be-card-body h-100" v-if="isShoppingAvailable">
          <be-form-error
            v-if="beFormErrorMessage"
            :message="beFormErrorMessage"
          />
          <ShoppingFormLohce v-if="isLohceShopping" :service="service" />

          <div class="be-row position-relative" v-if="!hideServiceSelect">
            <div class="col-lg-6 px-0 pr-lg-4">
              <be-multiselect
                :options="services"
                v-model="ddd"
                :disabled="services.length === 1"
                track-by="id"
                label="label"
                :inputLabel="$t('base.choose_service')"
                :placeholder="$t('common.choose')"
                :close-on-select="true"
                :initialValue="service"
                @input="onChooseService"
              />
            </div>
            <div class="col-lg-6 px-0 position-unset" v-if="!isLohceShopping">
              <template v-if="!isConfirm">
                <UserServiceSelector
                  :onToggleEdit="onToggleEdit"
                  :service="service"
                  :loading="loading"
                  v-if="isUserServiceShop"
                  v-model="userService"
                />
              </template>
            </div>
            <div v-if="showEditPlaceholder" class="divider my-30"></div>
            <div
              v-if="showEditPlaceholder"
              :class="{
                'edit-placeholder': true,
                'edit-placeholder-with-switch': isRememberUserService,
                'edit-placeholder-with-card-inputs': isVisaUserServiceForm,
              }"
            ></div>
          </div>
          <div class="card-body p-0" v-if="!isLohceShopping">
            <template v-if="!isConfirm">
              <div class="">
                <div
                  v-if="showEditPlaceholder && isCanalPlusShopping"
                  class="divider mt-30"
                ></div>
                <CanalPlusProductList
                  :isInvalid="$v.quoteInput.amount.$error"
                  v-if="isCanalPlusShopping"
                  v-model="quoteInput"
                  :errorMessage="canalErrorMessage"
                  @input="$v.quoteInput.amount.$touch()"
                />
              </div>
              <BuyCardFormInput
                v-if="isBuyingCardShopping"
                @process="handleVisaCardProcess"
              />

              <div
                class="be-row col-12 col-lg-6 px-0 mt-4 pr-lg-4"
                v-if="isManuelReferenceBill"
              >
                <BaseInput
                  label="Numéro de facture"
                  type="text"
                  :isInvalid="$v.userService.reference.$error"
                  v-model="userService.reference"
                  placeholder="Entrez le numéro de facture"
                  :description="
                    isSodeciBillRef &&
                      'Entrez les 09 premiers chiffres représentant la référence de votre contrat'
                  "
                  :errorMessage="
                    $v.userService.reference.$error &&
                      ((!$v.userService.reference.required &&
                        'Veuillez saisir le numéro de votre facture') ||
                        ((!$v.userService.reference.minLength ||
                          !$v.userService.reference.maxLength) &&
                          `La valeur du numéro de facture doit être ${numberBillLength} caractères`))
                  "
                  @input="$v.userService.reference.$touch()"
                />
              </div>
              <div
                class="divider my-30"
                v-if="
                  service !== null &&
                    (!isBuyingCardShopping || this.visaCard !== null)
                "
              ></div>
              <div class="be-row" v-if="canShowAmountField">
                <div class="col-lg-6 px-0 pr-lg-4">
                  <BaseInput
                    :label="$t('common.amount')"
                    type="number"
                    :isInvalid="$v.quoteInput.amount.$error"
                    v-model="quoteInput.amount"
                    :placeholder="$t('common.insert_amount')"
                    @input="$v.quoteInput.amount.$touch()"
                    :errorMessage="amountErrorMessage"
                  />
                </div>
              </div>
            </template>

            <ShoppingConfirm
              v-if="isConfirm"
              :quote="quote"
              :quoteInput="quoteInput"
              :service="service"
              :userService="userService"
              :feeAmount="feeAmount"
              :totalAmount="totalAmount"
              :bill_number="bill_number"
              :due_date="due_date"
              :visaCard="visaCard"
            />
          </div>
          <template
            v-if="
              service !== null &&
                (!isBuyingCardShopping || this.visaCard !== null) &&
                service.api !== serverApiCode.lohce
            "
          >
            <div class="be-row justify-content-end">
              <div
                class="col be-row justify-content-center col-sm-auto px-0 mt-4 mt-lg-0 align-items-center"
                v-if="isConfirm"
              >
                <span
                  @click.prevent="cancelForm"
                  role="button"
                  type="button"
                  class="f-w-600 fermer pointer mr-4"
                >
                  {{ $t("common.back") }}
                </span>
              </div>
              <div
                class="col be-row justify-content-center col-sm-auto px-0 mt-4 mt-lg-0"
              >
                <BaseButton
                  buttonClass="be-btn primary blue lg mb-0"
                  :disabled="$v.$invalid || loading"
                  :loading="loading"
                  type="button"
                  :showLoading="true"
                  @click.prevent="handleShoppingBtn"
                >
                  {{ btnTextAndIcon.text }}
                </BaseButton>
              </div>
            </div>
          </template>
        </div>
        <div class="service-not-available" v-else>
          <i class="not-available-card icon-card"></i>
          <span class="not-available-text">{{
            $t("shopping.service_not_available")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  accessServiceControl,
  accessTypeControl,
} from "@/middleware/shoppingMiddleware";
import {
  fieldLength,
  serverApiCode,
  serviceIndex,
  typeServiceCode,
} from "@/helpers/constants";
import ShoppingFormLohce from "../../components/shopping/ShoppingFormLohce";
import UserServiceSelector from "../../components/shopping/UserServiceSelector";
import CanalPlusProductList from "../../components/shopping/CanalPlusProductList";
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import ShoppingConfirm from "../../components/shopping/ShoppingConfirm";
import BuyCardFormInput from "../../components/shopping/BuyCardFormInput";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeMultiselect from "../../components/common/BeMultiselect";
import BeFormError from "@/components/common/BeFormError.vue";
import { getAmountValidationStep } from "../../helpers/utils";

export default {
  name: "ShoppingForm",
  components: {
    BuyCardFormInput,
    ShoppingConfirm,
    CanalPlusProductList,
    UserServiceSelector,
    ShoppingFormLohce,
    HeaderWithDivider,
    BeMultiselect,
    BeFormError,
  },

  beforeRouteUpdate(routeTo, routeFrom, next) {
    Promise.all([
      accessTypeControl(routeTo, routeFrom, next),
      accessServiceControl(routeTo, routeFrom, next),
    ]).then(() => {
      next();
    });
  },

  props: {
    serviceType: {
      type: Object,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    service: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showEditPlaceholder: false,
      loading: false,
      error: null,
      userService: {
        reference: null,
      },
      quote: null,
      quoteInput: {
        amount: null,
      },
      visaCard: null,
      ddd: null,
      serverApiCode,
    };
  },

  watch: {
    service() {
      this.cancelForm();
      this.showEditPlaceholder = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["userStats", "userConfig", "currency"]),
    ...mapState("shopping", ["bills"]),

    canalErrorMessage() {
      if (this.$v.quoteInput.amount.$error) {
        if (!this.$v.quoteInput.amount.required) {
          return "Veuillez choisir un bouquet";
        }
        if (!this.$v.quoteInput.amount.minValue) {
          return `Le montant minimum est ${this.minAmountValue} ${this.currency}`;
        }
        if (!this.$v.quoteInput.amount.maxValue) {
          return `Le montant maximum utilisable est ${this.maxAmountValue} ${this.currency}`;
        }
      }
      return "";
    },
    amountErrorMessage() {
      if (this.$v.quoteInput.amount.$error) {
        if (!this.$v.quoteInput.amount.required) {
          return "Veuillez saisir le montant de l'opération";
        }
        if (!this.$v.quoteInput.amount.minValue) {
          return `Le montant minimum est ${this.minAmountValue} ${this.currency}`;
        }
        if (!this.$v.quoteInput.amount.maxValue) {
          return `Le montant maximum est ${this.maxAmountValue} ${this.currency}`;
        }
      }
      return "";
    },
    hideServiceSelect() {
      return (
        this.services.length < 1 ||
        this.isConfirm ||
        (this.service && this.service.api === serverApiCode.lohce)
      );
    },
    isShoppingAvailable() {
      return this.services.length > 0;
    },
    isLohceShopping() {
      if (this.service) return this.service.api === serverApiCode.lohce;
      return false;
    },
    isCanalPlusShopping() {
      if (this.service) return this.service.index === serviceIndex.canal;
      return false;
    },
    isBuyingCardShopping() {
      if (this.service) return this.service.index === serviceIndex.buy_uba;
      return false;
    },
    canShowAmountField() {
      if (this.service)
        return (
          this.service.type_service.code === typeServiceCode.recharge ||
          this.service.type_service.code === typeServiceCode.charge_card ||
          (this.service.type_service.code === typeServiceCode.invoice &&
            !this.isSearchableBill)
        );
      else return false;
    },
    isUserServiceShop() {
      if (this.service !== null) {
        if (this.service.api !== serverApiCode.lohce) {
          if (this.service.type_service.code === typeServiceCode.invoice)
            return (
              this.service.index === serviceIndex.eneo ||
              this.service.index === serviceIndex.camwatter ||
              this.service.index === serviceIndex.cie
            );
          else
            return this.service.type_service.code !== typeServiceCode.sell_card;
        } else return false;
      } else return false;
    },
    title() {
      if (this.service) {
        if (this.service.api === serverApiCode.lohce)
          return { icon: "fas fa-search-plus", label: "Recherche un voyage" };
        if (this.service.type_service.code === typeServiceCode.recharge)
          return {
            icon: "fas fa-phone-volume",
            label: `Recharge d'unités ${this.service.label}`,
          };
        if (this.service.type_service.code === typeServiceCode.invoice)
          return {
            icon: "fa fa-credit-card",
            label: `Payer une facture ${this.service.label}`,
          };
        if (this.service.type_service.code === typeServiceCode.subscription)
          return {
            icon: "fa fa-credit-card",
            label: `Payer un abonnement ${this.service.label}`,
          };
        return { icon: "fa fa-credit-card", label: this.serviceType.web_menu };
      } else
        return { icon: "fa fa-credit-card", label: this.serviceType.web_menu };
    },
    showCancelBtn() {
      return this.quote !== null;
    },
    btnTextAndIcon() {
      if (this.service) {
        if (this.showCancelBtn)
          return { icon: "fas fa-check", text: "Confirmer" };
        if (
          this.service.type_service.code === typeServiceCode.recharge ||
          this.service.type_service.code === typeServiceCode.sell_card ||
          this.service.type_service.code === typeServiceCode.subscription ||
          this.service.type_service.code === typeServiceCode.charge_card ||
          !this.isSearchableBill
        )
          return {
            icon: "fas fa-angle-right",
            text: "Initier l'opération",
          };

        return { icon: "fas fa-search", text: "Rechercher" };
      } else return { icon: "fas fa-search", text: "Rechercher" };
    },
    isSearchableBill() {
      if (this.service.type_service.code === typeServiceCode.invoice)
        return (
          this.service.index === serviceIndex.eneo ||
          this.service.index === serviceIndex.camwatter
        );
      else return false;
    },
    minAmountValue() {
      if (this.service) {
        return this.canShowAmountField ? this.service.min_amount : 0;
      } else return 0;
    },
    maxAmountValue() {
      if (this.service)
        return this.userStats.balance > this.service.max_amount
          ? this.service.max_amount
          : this.userStats.balance;
      else return 0;
    },
    isConfirm() {
      return this.userService.reference !== null && this.quote !== null;
    },
    feeAmount() {
      if (this.quote) return parseFloat(this.quote.bwt_frais);
      else return 0;
    },
    totalAmount() {
      if (this.quote) {
        if (this.service.type_service.code === typeServiceCode.invoice)
          return (
            parseFloat(this.quote.amount) + parseFloat(this.quote.bwt_frais)
          );
        else
          return (
            parseFloat(this.quote.price) + parseFloat(this.quote.bwt_frais)
          );
      } else return 0;
    },
    bill_number() {
      if (this.bills.length === 1) return this.bills[0].bill_number;
      else return null;
    },
    due_date() {
      if (this.bills.length === 1) return this.bills[0].due_date;
      else return null;
    },
    isCamwatterBillRef() {
      return this.service
        ? this.service.index === serviceIndex.camwatter
        : false;
    },
    isSodeciBillRef() {
      return this.service ? this.service.index === serviceIndex.sodeci : false;
    },
    isManuelReferenceBill() {
      if (this.service) return this.isSodeciBillRef;
      else return false;
    },
    numberBillLength() {
      if (this.isCamwatterBillRef) {
        return !this.$v.userService.reference.minLength
          ? `supérieur à ${fieldLength.camwatter_min}`
          : `inférieur à ${fieldLength.camwatter_max}`;
      } else return fieldLength.sodeci_ref;
    },
    amountValidationStep() {
      return this.service ? getAmountValidationStep(this.service.api, 50) : 50;
    },
    isRememberUserService() {
      return this.service.index === serviceIndex.eneo;
    },
    isVisaUserServiceForm() {
      return this.service.type_service.code === typeServiceCode.charge_card;
    },
    beFormErrorMessage() {
      if (this.error || !this.$v.validConfirm) {
        if (this.error) return this.error;
        if (!this.$v.validConfirm) {
          return "Le solde de votre compte est insuffisant pour effectuer l'opération";
        }
      }
      return "";
    },
  },

  methods: {
    onToggleEdit(value) {
      this.showEditPlaceholder = value;
    },
    onChooseService(model) {
      if (
        model !== null &&
        this.$route.params.index !== model.index.toLowerCase()
      ) {
        this.$router.push({
          name: "shopping.form",
          params: {
            type_code: this.serviceType.code.toLowerCase(),
            index: model.index.toLowerCase(),
          },
        });
      }
    },
    cancelForm() {
      this.userService = { reference: null };
      this.error = null;
      this.quote = null;
      this.quoteInput = { amount: null };
      this.visaCard = null;
    },

    handleVisaCardProcess(visaCard) {
      this.visaCard = visaCard;
      const created_at = new Date();
      this.userService.reference =
        "BV" +
        created_at.getFullYear() +
        created_at.getMonth() +
        created_at.getHours() +
        created_at.getSeconds();
      this.quoteInput.amount = this.userConfig.buy_card_amount;
      this.handleShoppingBtn();
    },

    handleShoppingBtn() {
      this.$v.$touch();
      if (!this.$v.invalid) {
        this.loading = true;
        this.error = null;
        if (this.isConfirm) {
          this.$store
            .dispatch("shopping/confirmShopping", {
              service: this.service,
              userService: this.userService,
              quote: this.quote,
              visaCard: this.visaCard,
            })
            .then(() => {
              this.showEditPlaceholder = false;
              this.$router.push({
                name: "shopping.list",
              });
            })
            .catch(error => {
              if (error.message) this.error = error.message;
              this.loading = false;
            });
        } else {
          if (this.isSearchableBill)
            this.$store
              .dispatch("shopping/searchBills", {
                code: this.service.code,
                reference: this.userService.reference,
              })
              .then(data => {
                if (this.bills.length === 1) {
                  this.quote = data;
                  this.loading = false;
                } else {
                  this.showEditPlaceholder = false;
                  this.$router.push({
                    name: "shopping.bills",
                    params: {
                      ...this.$route.params,
                      reference: this.userService.reference,
                    },
                  });
                }
              })
              .catch(err => {
                if (err.message) this.error = err.message;
                this.loading = false;
              });
          else
            this.$store
              .dispatch("shopping/quoteShopping", {
                serviceCode: this.service.code,
                quoteInput: this.quoteInput,
              })
              .then(quote => {
                this.showEditPlaceholder = false;
                this.quote = quote;
                this.loading = false;
              })
              .catch(error => {
                if (error.message) this.error = error.message;
                this.loading = false;
              });
        }
      }
    },
  },

  validations() {
    return {
      userService: {
        reference: {
          required,
          minLength: this.isManuelReferenceBill
            ? minLength(
                this.isCamwatterBillRef
                  ? fieldLength.camwatter_min
                  : fieldLength.sodeci_ref
              )
            : false,
          maxLength: this.isManuelReferenceBill
            ? maxLength(
                this.isCamwatterBillRef
                  ? fieldLength.camwatter_max
                  : fieldLength.sodeci_ref
              )
            : false,
        },
      },
      quoteInput: {
        amount: {
          required: requiredIf(
            () => this.canShowAmountField || this.isCanalPlusShopping
          ),
          minValue: minValue(this.minAmountValue),
          maxValue: maxValue(this.maxAmountValue),
          validAmount: value => {
            if (value) {
              return (
                parseFloat(this.quoteInput.amount) %
                  this.amountValidationStep ===
                0
              );
            } else return true;
          },
        },
      },
      validConfirm: value => {
        if (value) {
          if (this.isConfirm) return this.userStats.balance >= this.totalAmount;
          else return true;
        } else return true;
      },
    };
  },
};
</script>

<style scoped></style>
