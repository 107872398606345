<template>
  <div class="d-flex mt-4 mt-lg-0">
    <template>
      <div class="w-100px col-11 col-lg-10 flex-1 px-0 pr-4">
        <be-multiselect
          v-model="user_service_id"
          :inputLabel="userServiceName"
          :options="remappedUserServices"
          :placeholder="$t('common.choose_o')"
          close-on-select
          label="userServiceSelect"
          onlyKey
          track-by="id"
          @input="closePlaceholder"
        />
      </div>
      <div class="edit-btn">
        <button
          :disabled="!enabledEditBtn"
          :title="$t('common.update')"
          class="be-btn secondary blue circle-btn lg"
          role="button"
          type="button"
          @click="openUserServiceForm"
        >
          <i class="icon-edit"></i>
        </button>
      </div>
    </template>
    <div v-if="showUserServiceForm" class="col-lg-12 px-0 card-edit-form">
      <UserServiceForm
        :can-show-cancel-btn="canShowCancelBtn"
        :service="service"
        :user-service="userService"
        @close="cancelUserServiceForm"
        @saving="onSaveUserService"
      />
    </div>
  </div>
</template>

<script>
import { serviceIndex, typeServiceCode } from "../../helpers/constants";
// import UserServiceSelect from "./UserServiceSelect";
import UserServiceForm from "./UserServiceForm";
import BeMultiselect from "../../components/common/BeMultiselect";

export default {
  name: "UserServiceSelector",
  components: {
    UserServiceForm,
    // UserServiceSelect,
    BeMultiselect,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onToggleEdit: {
      type: Function,
      default: () => {},
    },
  },

  watch: {
    service(value) {
      if (value) this.user_service_id = null;
      this.showUserServiceForm = false;
      this.onToggleEdit(false);
    },
    user_service_id(value) {
      if (value === "-1") {
        this.showUserServiceForm = true;
        this.onToggleEdit(true);
        this.$emit("input", { reference: null });
      } else {
        if (value) {
          const userService = this.service.user_services.find(
            us => us.id === value
          );
          if (userService !== undefined)
            this.$emit("input", { ...userService });
          else this.$emit("input", { reference: null });
        } else this.$emit("input", { reference: null });
      }
    },
  },

  data() {
    return {
      user_service_id: null,
      showUserServiceForm: false,
    };
  },

  computed: {
    userServiceName() {
      switch (this.service.type_service.code) {
        case typeServiceCode.recharge:
          return this.$t("common.phone_number");
        case typeServiceCode.subscription:
          return this.$t("common.card_number") + " " + this.service.label;
        case typeServiceCode.invoice:
          return this.service.index === serviceIndex.eneo ||
            this.service.index === serviceIndex.camwatter
            ? this.$t("common.contract_number")
            : this.$t("common.counter_number");
        case typeServiceCode.charge_card:
          return this.$t("common.card") + " " + this.service.label;
        default:
          return this.$t("common.choose_o");
      }
    },
    userService() {
      if (this.user_service_id) {
        const userService = this.service.user_services.find(
          us => us.id === this.user_service_id
        );
        return userService !== undefined ? userService : null;
      } else return null;
    },
    enabledEditBtn() {
      if (this.loading) return false;
      if (this.showUserServiceForm) return false;
      return this.user_service_id ? this.user_service_id !== "-1" : false;
    },
    canShowCancelBtn() {
      return this.service.user_services.length >= 0;
    },
    remappedUserServices() {
      return [
        ...this.service.user_services.map(item => {
          let userServiceSelectValue = "";
          try {
            if (
              this.service.type_service.code === typeServiceCode.charge_card
            ) {
              const visa = JSON.parse(item.reference);
              userServiceSelectValue = `${visa.bank} ${
                this.isConfirm ? "<br/> ID : " : " - "
              }${visa.ref}`;
            } else userServiceSelectValue = item.reference;
          } catch (e) {
            userServiceSelectValue = item.reference;
          }
          return {
            ...item,
            userServiceSelect: userServiceSelectValue,
          };
        }),
        {
          userServiceSelect:
            this.$t("common.add_with_plus") +
            ` ${this.userServiceName.toLowerCase()}`,
          id: "-1",
        },
      ];
    },
  },

  methods: {
    openUserServiceForm() {
      if (this.enabledEditBtn) {
        this.showUserServiceForm = true;
        this.onToggleEdit(true);
      }
    },
    closePlaceholder(val) {
      if (val !== "-1") {
        this.showUserServiceForm = false;
        this.onToggleEdit(false);
      }
    },
    cancelUserServiceForm() {
      this.showUserServiceForm = false;
      this.onToggleEdit(false);
      if (this.user_service_id === "-1") this.user_service_id = null;
    },
    onSaveUserService(userServiceId) {
      this.user_service_id = userServiceId;
      this.showUserServiceForm = false;
      this.onToggleEdit(false);
    },
  },
};
</script>

<style scoped></style>
