<template>
  <form @submit.prevent="handleBuyCardInit" class="form">
    <div class="be-row">
      <div class="px-0 col-lg-6 mt-4 pr-lg-4">
        <BaseInput
          label="Titulaire de la carte (Nom & Prénom)"
          type="text"
          :isInvalid="$v.cardDemand.name.$error"
          v-model="cardDemand.name"
          placeholder="Entrez le nom et le prénom"
          @blur="$v.cardDemand.name.$touch()"
          :errorMessage="
            $v.cardDemand.name.$error &&
              !$v.cardDemand.name.required &&
              'Veuillez saisir le nom et le prénom'
          "
        />
      </div>
      <div class="px-0 mt-4 col-lg-6">
        <BaseDatepicker
          v-model="cardDemand.date_naiss"
          label="Date de naissance"
          :is-invalid="$v.cardDemand.date_naiss.$error"
          @blur="$v.cardDemand.date_naiss.$touch()"
          :not-after="notAfterDate"
          placeholder="Choisir la date du départ"
        />
        <template v-if="$v.cardDemand.date_naiss.$error">
          <label class="small form-text text-danger f-12 text-right">
            <em v-if="$v.cardDemand.date_naiss.required"
              >Veuillez choisir la date de naissance
            </em>
          </label>
        </template>
      </div>
    </div>
    <div class="be-row">
      <div class="px-0 mt-4 col-lg-6 pr-lg-4">
        <div class="">
          <BaseInput
            isPhoneInput
            :countryCode="calling_code"
            label="Numéro mobile"
            type="tel"
            id="phoneNumber"
            v-model="cardDemand.phone"
            placeholder="Entrez le numéro de téléphone"
            :class="{
              'is-invalid': $v.cardDemand.phone.$error,
            }"
            @blur="$v.cardDemand.phone.$touch()"
            :errorMessage="
              $v.cardDemand.phone.$error &&
                !$v.cardDemand.phone.required &&
                'Le numéro de téléphone est obligatoire'
            "
          />
        </div>
      </div>
      <div class="px-0 mt-4 col-lg-6">
        <BaseInput
          label="Email à associer à la carte"
          type="email"
          :isInvalid="$v.cardDemand.email.$error"
          v-model="cardDemand.email"
          placeholder="Entrez l'adresse email"
          @blur="$v.cardDemand.email.$touch()"
          :errorMessage="emailInputError"
        />
      </div>
    </div>
    <div class="be-row">
      <div class="px-0 mt-4 col-lg-6 pr-lg-4">
        <BaseInput
          label="Ville ou Commune de résidence"
          type="text"
          :isInvalid="$v.cardDemand.ville.$error"
          v-model="cardDemand.ville"
          placeholder="Entrez la ville de résidence"
          @blur="$v.cardDemand.ville.$touch()"
          :errorMessage="
            $v.cardDemand.ville.$error &&
              !$v.cardDemand.ville.required &&
              'Veuillez saisir la ville de résidence'
          "
        />
      </div>
      <div class="px-0 mt-4 col-lg-6 ">
        <BaseInput
          label="Quartier"
          type="text"
          :isInvalid="$v.cardDemand.quartier.$error"
          v-model="cardDemand.quartier"
          placeholder="Entrez le quartier"
          @blur="$v.cardDemand.quartier.$touch()"
          :errorMessage="
            $v.cardDemand.quartier.$error &&
              !$v.cardDemand.quartier.required &&
              'Veuillez saisir le quartier'
          "
        />
      </div>
    </div>
    <div class="be-row">
      <div class="px-0 mt-4 col-lg-6 pr-lg-4">
        <be-multiselect
          :options="typeOfPassports"
          v-model="cardDemand.identify_type"
          inputLabel="Type de la pièce d'identité"
          @input="$v.cardDemand.identify_type.$touch()"
          placeholder="Choisir le type d'identification"
          onlyKey
          label="label"
          trackBy="value"
          :errorMessage="
            $v.cardDemand.identify_type.$error &&
            !$v.cardDemand.identify_type.required
              ? `Veuillez selectioner le type d'identification`
              : null
          "
        />
      </div>
      <div class="px-0 mt-4 col-lg-6 ">
        <BaseInput
          label="Numéro de la pièce d'identité"
          type="text"
          :isInvalid="$v.cardDemand.identify_value.$error"
          v-model="cardDemand.identify_value"
          placeholder="Entrez la valeur de l'identification"
          @blur="$v.cardDemand.identify_value.$touch()"
          :errorMessage="
            $v.cardDemand.identify_value.$error &&
              !$v.cardDemand.identify_value.required &&
              `Veuillez saisir la valeur de l'identification`
          "
        />
      </div>
    </div>
    <div class="divider my-30" />
    <div class="be-row justify-content-end">
      <BaseButton
        buttonClass="be-btn primary lg blue mb-0"
        :disabled="$v.$invalid || loading"
        :loading="loading"
        :show-loading="true"
        type="submit"
      >
        Initier l'opération
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BeMultiselect from "../common/BeMultiselect.vue";

export default {
  name: "BuyCardFormInput",
  components: { BeMultiselect },
  data() {
    return {
      loading: false,
      cardDemand: {
        name: null,
        date_naiss: null,
        phone: null,
        email: null,
        ville: null,
        quartier: null,
        identify_type: null,
        identify_value: null,
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),

    notAfterDate() {
      let limit = new Date();
      limit.setFullYear(limit.getFullYear() - 15);
      return limit;
    },
    emailInputError() {
      if (this.$v.cardDemand.email.$error) {
        if (!this.$v.cardDemand.email.required) {
          return "L'adresse email est obligatoire";
        }
        if (!this.$v.cardDemand.email.email) {
          return "L'adresse email n'est pas valide";
        }
      }
      return "";
    },
    typeOfPassports() {
      return [
        { value: "CNI", label: "Carte national d'idenitité (CNI)" },
        { value: "PERMIS DE CONDUIRE", label: "Permis de conduire" },
        { value: "PASSPORT", label: "Passport" },
        { value: "ATTESTATION", label: "Attestation" },
        { value: "CARTE CONSULAIRE", label: "Carte consulaire" },
      ];
    },
  },

  methods: {
    handleBuyCardInit() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$emit("process", this.cardDemand);
      }
    },
  },

  validations() {
    return {
      cardDemand: {
        name: { required },
        date_naiss: { required },
        phone: { required },
        email: { required, email },
        ville: { required },
        quartier: { required },
        identify_type: { required },
        identify_value: { required },
      },
    };
  },
};
</script>

<style scoped></style>
