<template>
  <div class="shoppingConfirmCard">
    <div class="title">
      Votre solde sera débité d'un montant de
      {{ `${totalAmount} ${currency}` }} pour cette opération
    </div>
    <div class="shopping-confirm-body be-row">
      <div class="col-4">
        <div class="sh-card-title">
          <span>{{ userServiceName }}</span>
        </div>
        <div class="sh-card-description">
          <UserServiceSelect
            :reference="userService.reference"
            :serviceIndex="service.index"
            :is-confirm="true"
            :typeServiceCode="service.type_service.code"
          />
        </div>
      </div>

      <div class="col-4" v-if="bill_number">
        <div class="sh-card-title">
          <span>
            <span class="d-inline d-sm-none">N°</span>
            <span class="d-none d-sm-inline">Numéro</span> de facture
          </span>
        </div>
        <div class="sh-card-description">
          {{ bill_number }}
        </div>
      </div>

      <div class="col-4" v-if="due_date">
        <div class="sh-card-title">
          Date limite<span class="d-none d-sm-inline"> de paiement</span>
        </div>
        <div class="sh-card-description">
          {{ due_date | moment("dddd DD-MM-YYYY") }}
        </div>
      </div>

      <template v-if="visaCard">
        <div class="col-4">
          <div class="sh-card-title">
            <span
              >Titulaire
              <span class="d-none d-sm-inline">de la carte</span></span
            >
          </div>
          <div class="sh-card-description">
            {{ visaCard.name }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span>
              <span class="d-none d-sm-inline">Date de naissance</span>
              <span class="d-inline d-sm-none">Aniversaire</span>
            </span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.date_naiss | moment("DD-MMMM-YYYY") }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span>Téléphone</span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.phone }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span>Email</span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.email }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span
              >Ville<span class="d-none d-sm-inline"> / Commune</span>
            </span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.ville }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span>Quartier</span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.quartier }}
          </div>
        </div>

        <div class="col-4">
          <div class="sh-card-title">
            <span class="text-capitalize">
              <span class="d-none d-sm-inline">Pièce d'</span>identité
            </span>
          </div>
          <div class="sh-card-description">
            {{ visaCard.identify_type }}
            <span class="d-block text-right">
              <b>N°</b> : {{ visaCard.identify_value }}</span
            >
          </div>
        </div>
      </template>

      <div class="col-4" v-if="quoteInput.name">
        <div class="sh-card-title">
          <span>Forfait</span>
        </div>
        <div class="sh-card-description">
          {{ quoteInput.name }}
        </div>
      </div>

      <div class="col-4">
        <div class="sh-card-title">
          <span>Montant</span>
        </div>
        <div class="sh-card-description">
          {{ `${quote.amount} ${currency}` }}
        </div>
      </div>

      <div class="col-4" v-if="visaCard || isChargeVisa">
        <div class="sh-card-title">
          <span v-if="visaCard">Transport</span>
          <span v-if="isChargeVisa">Frais de banque</span>
        </div>
        <div class="sh-card-description">
          {{ `${quote.frais} ${currency}` }}
        </div>
      </div>

      <div class="col-4">
        <div class="sh-card-title">
          <span>Frais</span>
        </div>
        <div class="sh-card-description">{{ `${feeAmount} ${currency}` }}</div>
      </div>

      <div class="col-4">
        <div class="sh-card-title">
          <span class="text-uppercase f-w-600 text-muted">Prix total</span>
        </div>
        <div class="sh-card-description">
          {{ `${totalAmount} ${currency}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { serviceIndex, typeServiceCode } from "../../helpers/constants";
import UserServiceSelect from "./UserServiceSelect";

export default {
  name: "ShoppingConfirm",
  components: { UserServiceSelect },
  props: {
    userService: {
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      required: true,
    },
    quoteInput: {
      type: Object,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    bill_number: {
      type: [Number, String],
      default: null,
    },
    due_date: {
      type: [Number, String],
      default: null,
    },
    visaCard: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      passengers: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["userStats", "phone", "currency"]),
    userServiceName() {
      switch (this.service.type_service.code) {
        case typeServiceCode.recharge:
          return "Téléphone";
        case typeServiceCode.subscription:
          return "Carte " + this.service.label;
        case typeServiceCode.invoice:
          return this.service.index === serviceIndex.eneo ||
            this.service.index === serviceIndex.camwatter
            ? "N° Contrat "
            : this.service.index === serviceIndex.cie
            ? "N° Compteur"
            : "N° Facture";
        case typeServiceCode.charge_card:
          return "Carte " + this.service.label;
        case typeServiceCode.sell_card:
          return "Reférence";
        default:
          return "";
      }
    },
    isChargeVisa() {
      if (this.service)
        return this.service.type_service.code === typeServiceCode.charge_card;
      else return false;
    },
  },
};
</script>

<style scoped></style>
