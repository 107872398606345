<template>
  <div class="card-body p-2">
    <be-form-error v-if="error" :message="error" />

    <form @submit.prevent="handleSearchTravels" class="form">
      <div class="be-row">
        <div class="col-12 col-xl-6 px-0 mb-4 px-2">
          <BaseDatepicker
            label="Date du voyage"
            :is-invalid="$v.searchField.date.$error"
            @blur="$v.searchField.date.$touch()"
            :not-before="dateNotBefore"
            v-model="searchField.date"
            placeholder="Choisir la date du départ"
          />
          <template v-if="$v.searchField.date.$error">
            <span
              class="input-error-message"
              v-if="!$v.searchField.date.required"
              >Veuillez choisir la date du départ
            </span>
          </template>
        </div>
      </div>
      <div class="be-row">
        <div class="col-12 col-xl-6 px-0 mb-4 px-2">
          <be-multiselect
            v-model="searchField.depart"
            placeholder="Choisir la ville de départ"
            inputLabel="Ville de départ"
            :options="departureCities"
            :disabled="departureCities.length === 0"
            trackBy="label"
            label="label"
            :errorMessage="
              $v.searchField.depart.$error && !$v.searchField.depart.required
                ? 'Veuillez choisir la ville de départ'
                : ''
            "
            onlyKey
            noChange
            :is-invalid="$v.searchField.depart.$error"
            @input="$v.searchField.depart.$touch()"
          />
        </div>
        <div class="col-12 col-xl-6 px-0 mb-4 px-2 multiselect-custom">
          <be-multiselect
            v-model="searchField.arrive"
            placeholder="Choisir la ville d'arrivée"
            inputLabel="Ville de d'arrivée"
            :options="arrivalCities"
            label="label"
            :disabled="arrivalCities.length === 0"
            onlyKey
            trackBy="label"
            :errorMessage="searchFieldArriveValidities"
            @input="$v.searchField.arrive.$touch()"
          />
        </div>
      </div>

      <div class="divider my-30"></div>
      <div class="form-group mb-0 text-lg-right">
        <div class="row justify-content-end">
          <BaseButton
            buttonClass="be-btn primary lg blue i-left mb-0"
            :disabled="$v.$invalid || loading"
            :loading="loading"
            :show-loading="true"
          >
            <i class="icon-search"></i>
            Rechercher
          </BaseButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import BeMultiselect from '../common/BeMultiselect.vue';
import BeFormError from '@/components/common/BeFormError.vue';

export default {
  name: 'ShoppingFormLohce',
  components: { BeMultiselect, BeFormError },
  props: {
    service: {
      type: Object,
      required: true,
    },
    isTraveling: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      error: null,
      searchField: {
        date: new Date(),
        depart: null,
        arrive: null,
      },
    };
  },

  computed: {
    ...mapState('shopping', ['cities', 'agencies']),
    departureCities() {
      return this.searchField.arrive
        ? this.cities.filter(c => c.label !== this.searchField.arrive)
        : this.cities;
    },
    arrivalCities() {
      return this.searchField.depart
        ? this.cities.filter(c => c.label !== this.searchField.depart)
        : this.cities;
    },
    searchFieldArriveValidities() {
      if (this.$v.searchField.arrive.$error) {
        if (!this.$v.searchField.arrive.required) {
          return "Veuillez choisir la ville de d'arrivée";
        }
        if (!this.$v.searchField.arrive.sameAs) {
          return 'Vous devez choisir une ville différente';
        }
      }
      return '';
    },
    dateNotBefore() {
      let d = new Date();
      d.setDate(d.getDate() - 1);
      return d;
    },
  },

  created() {
    this.$store.dispatch('shopping/getTravelCities').then(() => {
      this.loading = false;
    });
  },

  methods: {
    handleSearchTravels() {
      if (!this.$v.$invalid) {
        this.loading = true;
        this.error = null;
        this.$store
          .dispatch('shopping/searchTravels', {
            code: this.service.index.toLowerCase(),
            field: {
              ...this.searchField,
              date: this.$moment(this.searchField.date).format('DD-MM-YYYY'),
            },
          })
          .then(agencies => {
            if (agencies.length === 0) {
              this.error = "Aucun voayge n'est disponible !";
            } else {
              if (this.$route.name !== 'shopping.form.travel')
                this.$router.push({
                  name: 'shopping.form.travel',
                  ...this.$route.params,
                  query: {
                    ...this.searchField,
                    date: this.$moment(this.searchField.date).format(
                      'DD-MM-YYYY'
                    ),
                  },
                });
            }
            this.loading = false;
          })
          .catch(err => {
            if (err.message) this.error = err.message;
          });
      }
    },
  },

  validations() {
    return {
      searchField: {
        date: { required },
        depart: { required },
        arrive: {
          required,
          sameAs: value => value !== this.searchField.depart,
        },
      },
    };
  },
};
</script>

<style scoped></style>
