<template>
  <div class="be-row">
    <div class="col-lg-6 px-0 pr-lg-4 mt-4">
      <BaseLoader :is-small="true" v-if="loading" />
      <be-multiselect
        inputLabel="Bouquet Canal+"
        v-else
        id="canal-plus"
        class="form-control"
        :value="currentValue"
        :class="{ 'is-invalid': isInvalid }"
        @input="$emit('input', $event)"
        @blur="$emit('blur')"
        :options="remappedCanalPlusProducts"
        trackBy="name"
        label="productTable"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BeMultiselect from "../../components/common/BeMultiselect";

export default {
  name: "CanalPlusProductList",
  components: {
    BeMultiselect,
  },
  props: {
    value: [String, Object, Number],
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState("shopping", ["canalPlusProducts"]),
    ...mapGetters("auth", ["currency"]),
    currentValue() {
      if (this.value) return this.value.index;
      return null;
    },
    remappedCanalPlusProducts() {
      return this.canalPlusProducts.map(item => {
        const productTable = `${item.name} - ${item.amount} ${this.currency}`;
        return {
          ...item,
          productTable,
        };
      });
    },
  },

  created() {
    this.$store.dispatch("shopping/getCanalPlusProductList").then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped></style>
