<template>
  <form class="be-row" @submit.prevent="handleUserServiceForm">
    <div v-if="!isVisaUserServiceForm" class="col-12 col-xl-6 px-0 pr-lg-4">
      <div v-if="isRememberUserService" class="mb-4 d-flex align-items-center">
        <div class="switch be-switch switch-primary m-r-10">
          <input
            id="switch-user-service-remember"
            v-model="userServiceInput.remember"
            type="checkbox"
          />
          <label class="cr mb-0" for="switch-user-service-remember"></label>
        </div>
        <span class="be-switch-label">{{
          $t("shopping.search_and_pay_bill")
        }}</span>
      </div>
      <div v-if="canShowReferenceInput" class="col-xl-12 px-0">
        <div>
          <BaseInput
            v-model="userServiceInput.reference"
            :class="{
              'is-invalid':
                $v.userServiceInput.reference.$error ||
                errors.reference !== undefined,
            }"
            :countryCode="calling_code"
            :description="
              isCieUserService
                ? $t('shopping.insert_9_digits')
                : $t('shopping.the_required_value_length', {
                    label: referenceLabel.toLowerCase(),
                    length: inputUserServiceLength,
                  })
            "
            :errorMessage="referenceLabelErrorMessage"
            :label="referenceLabel"
            :placeholder="
              $t('shopping.insert_the_value') + ` ${referenceLabel}`
            "
            isPhoneInput
            type="number"
            @change="resetErrors"
            @input="$v.userServiceInput.reference.$touch()"
          />
        </div>
      </div>
    </div>
    <template v-if="isVisaUserServiceForm">
      <div class="col-12 col-lg-6 px-0 pr-lg-4">
        <be-multiselect
          v-model="userServiceInput.visa.bank"
          :errorMessage="bankSelectErrorMessage"
          :initialValue="{
            label: userServiceInput.visa.bank,
            value: userServiceInput.visa.bank,
          }"
          :inputLabel="$t('shopping.select_the_bank')"
          :options="banks"
          :placeholder="$t('shopping.select_the_bank_card')"
          label="label"
          onlyKey
          trackBy="value"
          @input="$v.userServiceInput.visa.bank.$touch()"
        />
      </div>

      <div class="be-row col-12 mt-4 mt-lg-0 col-lg-6 px-0">
        <div class="col-12 px-0">
          <BaseInput
            v-model="userServiceInput.visa.nom"
            :class="{
              'is-invalid':
                $v.userServiceInput.visa.nom.$error || errors.nom !== undefined,
            }"
            :errorMessage="nomDuClientErrorMessage"
            :label="$t('common.customer_name')"
            :placeholder="$t('common.insert_name')"
            type="text"
            @input="$v.userServiceInput.visa.nom.$touch()"
          />
        </div>
      </div>

      <div class="be-row col-12 px-0 mt-4">
        <div class="col-12 px-0">
          <div class="be-row">
            <div class="col-12 col-md-6 px-0 pr-lg-4">
              <BaseInput
                v-model="userServiceInput.visa.card_bin"
                :class="{
                  'is-invalid':
                    $v.userServiceInput.visa.card_bin.$error ||
                    errors.card_bin !== undefined,
                }"
                :description="$t('shopping.first_6_digits_desc')"
                :errorMessage="numeroDeLaCarteErrors"
                :label="$t('shopping.card_number')"
                :placeholder="$t('shopping.first_6_digits')"
                type="number"
                @input="$v.userServiceInput.visa.card_bin.$touch()"
              />
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0 px-0">
              <BaseInput
                v-model="userServiceInput.visa.card_last_4digits"
                :class="{
                  'is-invalid':
                    $v.userServiceInput.visa.card_last_4digits.$error ||
                    errors.card_last_4digits !== undefined,
                }"
                :description="$t('shopping.last_4_digits_desc')"
                :errorMessage="derniersChiffresErrorMessage"
                :label="$t('shopping.last_4_digits')"
                :placeholder="$t('shopping.last_4_digits')"
                type="number"
                @input="$v.userServiceInput.visa.card_last_4digits.$touch()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="be-row col-12 px-0 mt-4">
        <div class="col-lg-6 px-0 pr-lg-4">
          <BaseInput
            v-model="userServiceInput.visa.ref"
            :class="{
              'is-invalid':
                $v.userServiceInput.visa.ref.$error || errors.ref !== undefined,
            }"
            :description="$t('shopping.insert_identification_number_desc')"
            :errorMessage="identificationClientErrorMessage"
            :label="$t('shopping.insert_identification_number')"
            :placeholder="
              $t('shopping.insert_identification_number_placeholder')
            "
            type="number"
            @blur="$v.userServiceInput.visa.ref.$touch()"
          />
        </div>
      </div>
    </template>
    <!-- TODO ON SAVE SHOW TOSTER -->
    <!--<div class="" v-if="error">
      <div class="col-lg-8 offset-lg-4">
        <span class="text-danger" v-if="error">
          {{ error }}
        </span>
      </div>
    </div> -->
    <div
      class="edit-buttons col-xl-6 col-12 be-row px-0 mt-2 justify-content-center justify-content-sm-start"
    >
      <button
        v-if="canShowCancelBtn"
        :disabled="loading"
        class="ml-lg-20 d-inline-flex be-btn secondary danger lg"
        role="button"
        type="button"
        @click.prevent="$emit('close')"
      >
        {{ $t("common.cancel") }}
      </button>
      <button
        :disabled="$v.$invalid || loading"
        :loading="loading"
        :show-loading="true"
        class="d-inline-flex be-btn primary green lg"
      >
        {{ $t("common.save") }}
      </button>
    </div>
  </form>
</template>

<script>
import { requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import {
  fieldLength,
  serviceIndex,
  typeServiceCode,
} from "../../helpers/constants";
import { phoneLengthByCallingCode } from "../../helpers/utils";
import { mapGetters } from "vuex";
import BeMultiselect from "../common/BeMultiselect.vue";

export default {
  components: { BeMultiselect },
  name: "UserServiceForm",

  props: {
    canShowCancelBtn: {
      type: Boolean,
      default: false,
    },
    userService: {
      type: Object,
      default: null,
    },
    service: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      errors: [],
      userServiceInput: {
        id: null,
        reference: null,
        remember: false,
        visa: {
          bank: null,
          ref: null,
          nom: null,
          card_bin: null,
          numero: null,
          card_last_4digits: null,
        },
      },
      banks: [
        { label: "Prepaid-UBA", value: "Prepaid-UBA" },
        { label: "Prepaid-Ecobank", value: "Prepaid-Ecobank" },
      ],
    };
  },

  created() {
    if (this.userService) {
      this.userServiceInput.id = this.userService.id;
      this.userServiceInput.remember = this.userService.remember;
      if (this.isVisaUserServiceForm) {
        const visa = JSON.parse(this.userService.reference);
        this.userServiceInput.visa.bank = visa.bank;
        this.userServiceInput.visa.ref = visa.ref;
        this.userServiceInput.visa.nom = visa.nom;
        if (visa.numero) {
          this.userServiceInput.visa.card_bin = visa.numero
            .toString()
            .substr(0, 6);
          this.userServiceInput.visa.card_last_4digits = visa.numero
            .toString()
            .substr(-4);
        } else {
          this.userServiceInput.visa.card_bin = visa.card_bin;
          this.userServiceInput.visa.card_last_4digits = visa.card_last_4digits;
        }
      } else {
        this.userServiceInput.reference = this.userService.reference;
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),
    canShowReferenceInput() {
      return (
        this.service.type_service.code === typeServiceCode.recharge ||
        this.service.type_service.code === typeServiceCode.subscription ||
        this.service.type_service.code === typeServiceCode.invoice
      );
    },
    referenceLabel() {
      if (this.canShowReferenceInput)
        return this.service.type_service.code === typeServiceCode.invoice
          ? this.$t("common.contract_number")
          : this.$t("common.number");
      else return "";
    },
    canSubmitField() {
      return false;
    },
    isVisaUserServiceForm() {
      return this.service.type_service.code === typeServiceCode.charge_card;
    },
    isPhoneUserService() {
      return this.service.type_service.code === typeServiceCode.recharge;
    },
    isCieUserService() {
      return this.service.index === serviceIndex.cie;
    },
    inputUserServiceLength() {
      if (this.isPhoneUserService)
        return phoneLengthByCallingCode(this.calling_code);
      if (this.isCieUserService) return fieldLength.cie_id;
      return null;
    },
    isRememberUserService() {
      return this.service.index === serviceIndex.eneo;
    },
    numeroDeLaCarteErrors() {
      if (
        this.$v.userServiceInput.visa.card_bin.$error ||
        this.errors.card_bin
      ) {
        if (
          !this.$v.userServiceInput.visa.card_bin.minLength ||
          !this.$v.userServiceInput.visa.card_bin.maxLength
        ) {
          return this.$t("shopping.card_length_6");
        }
        if (this.errors.card_bin) {
          return this.errors.card_bin[0];
        }
      }
      return "";
    },
    derniersChiffresErrorMessage() {
      if (
        this.$v.userServiceInput.visa.card_last_4digits.$error ||
        this.errors.card_last_4digits
      ) {
        if (
          !this.$v.userServiceInput.visa.card_last_4digits.minLength ||
          !this.$v.userServiceInput.visa.card_last_4digits.maxLength
        ) {
          return this.$t("shopping.card_length_4");
        }
        if (this.errors.card_last_4digits) {
          return this.errors.card_last_4digits[0];
        }
      }
      return "";
    },
    nomDuClientErrorMessage() {
      if (this.$v.userServiceInput.visa.nom.$error || this.errors.nom) {
        if (!this.$v.userServiceInput.visa.nom.required) {
          return this.$t("shopping.required.customer_name");
        }
        if (this.errors.nom) {
          return this.errors.nom[0];
        }
      }
      return "";
    },
    identificationClientErrorMessage() {
      if (this.$v.userServiceInput.visa.ref.$error || this.errors.ref) {
        if (
          !this.$v.userServiceInput.visa.ref.minLength ||
          !this.$v.userServiceInput.visa.ref.maxLength
        ) {
          return this.$t("shopping.customer_id_length");
        }
        if (this.errors.ref) {
          return this.errors.ref[0];
        }
      }
      return "";
    },
    referenceLabelErrorMessage() {
      if (this.error) {
        return this.error;
      }
      if (
        !this.$v.userServiceInput.reference.minLength ||
        !this.$v.userServiceInput.reference.maxLength
      ) {
        return (
          this.$t("utils.the_value_of") +
          ` ${this.referenceLabel.toLowerCase()} ` +
          this.$t("utils.must_be") +
          ` ${this.inputUserServiceLength} ` +
          this.$t("utils.characters")
        );
      }
      if (
        this.$v.userServiceInput.reference.$error &&
        !this.$v.userServiceInput.reference.required
      ) {
        return this.$t("shopping.ask_select") + ` ${this.referenceLabel}`;
      }
      if (this.errors.reference) {
        return this.errors.reference[0];
      }
      return "";
    },
    bankSelectErrorMessage() {
      if (this.$v.userServiceInput.visa.bank.$error || this.errors.bank) {
        if (!this.$v.userServiceInput.visa.bank.required) {
          return this.$t("shopping.choose_bank_required");
        }
        if (this.errors.bank) {
          return this.errors.bank[0];
        }
      }
      return "";
    },
  },

  methods: {
    resetErrors() {
      this.error = "";
    },
    handleUserServiceForm() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        if (this.isVisaUserServiceForm)
          this.userServiceInput.reference = JSON.stringify(
            this.userServiceInput.visa
          );
        if (this.userServiceInput.id) {
          this.$store
            .dispatch("shopping/updateUserService", {
              service: this.service,
              userService: this.userServiceInput,
            })
            .then(userService => {
              this.$emit("saving", userService.id);
              this.loading = false;
            })
            .catch(err => {
              if (err.response.data.message)
                this.error = err.response.data.message;
              if (err.response.data.errors)
                this.errors = err.response.data.errors;
              this.loading = false;
            });
        } else {
          this.$store
            .dispatch("shopping/addUserService", {
              service: this.service,
              userService: this.userServiceInput,
            })
            .then(userService => {
              this.$emit("saving", userService.id);
              this.loading = false;
            })
            .catch(err => {
              if (err.response.data.message)
                this.error = err.response.data.message;
              if (err.response.data.errors)
                this.errors = err.response.data.errors;
              this.loading = false;
            });
        }
      }
    },
  },

  validations() {
    return {
      userServiceInput: {
        reference: {
          required: requiredIf(() => {
            return this.canShowReferenceInput ? true : this.canSubmitField;
          }),
          minLength:
            this.isPhoneUserService || this.isCieUserService
              ? minLength(this.inputUserServiceLength)
              : false,
          maxLength:
            this.isPhoneUserService || this.isCieUserService
              ? maxLength(this.inputUserServiceLength)
              : false,
        },
        visa: {
          bank: {
            required: requiredIf(() => {
              return this.isVisaUserServiceForm;
            }),
          },
          ref: {
            required: requiredIf(() => {
              return this.isVisaUserServiceForm;
            }),
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          nom: {
            required: requiredIf(() => {
              return this.isVisaUserServiceForm;
            }),
          },
          card_bin: {
            required: requiredIf(() => {
              return this.isVisaUserServiceForm;
            }),
            minLength: minLength(6),
            maxLength: maxLength(6),
          },
          card_last_4digits: {
            required: requiredIf(() => {
              return this.isVisaUserServiceForm;
            }),
            minLength: minLength(4),
            maxLength: maxLength(4),
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
